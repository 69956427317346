@import "variable.scss";

html,body{
  line-height: 1;
  font-family: 'PingFang SC',sans-serif, arial, sans-serif, 'Droid Sans Fallback';
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: $background;
  color: $font-color;
  font-size: $font-size-normal;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}
.am-modal-content{
  .am-modal-header{
    .am-modal-title{
      font-size: 15px ;
    }
  }
  .am-modal-button-group-h{
    .am-modal-button{
      font-size: 14px !important;
    }
  }
  .am-modal-body{
    font-size: 14px;
    padding-bottom: 18px!important;
  }
}
.am-modal-content,.am-modal-transparent{
  width: 300px!important;
}
